/* eslint-disable no-irregular-whitespace */
import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import GetComeDineWithMeJson from '../../hooks/getComeDineWithMeJson.ts';

const ComeDineWithMe = () => {
   const [searchParams, setSearchParams] = useSearchParams();
   const [season, setSeason] = useState<string>('all');
   const [year, setYear] = useState<string>('all');
   const [sort, setSort] = useState<string>('desc');
   const [highlightResult, setHighlightResult] = useState<string>('false');

   const query = searchParams.get('q') || '';
   let data = GetComeDineWithMeJson();
   let seasonNumberOptions: React.JSX.Element[] = [];
   let yearOptions: React.JSX.Element[] = [];

   const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchParams({ q: e.target.value });
   };

   const onChangeHighlightResult = (e: React.ChangeEvent<HTMLInputElement>) => {
      setHighlightResult(e.target.checked ? 'true' : 'false');
   };

   const onChangeSeason = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSeason(e.target.value);
   };

   const onChangeYear = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setYear(e.target.value);
   };

   const onChangeSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSort(e.target.value);
   };

   const getHighlightedText = (text, highlight) => {
      if (highlightResult === 'false') return text;

      const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
      return (
         <span>
            {parts.map((part) => (part.toLowerCase() === highlight.toLowerCase() ? <span style={{ color: 'red' }}>{part}</span> : part))}
         </span>
      );
   };

   if (data !== null) {
      const seasonNumbers = [...new Set(data?.map((episode) => episode.seriesNumber))];
      seasonNumberOptions = seasonNumbers
         .sort((a, b) => a - b)
         .map((number) => (
            <option key={number} value={number}>
               {number}
            </option>
         ));

      const years = [...new Set(data?.map((episode) => episode.year).filter((e) => e))];
      yearOptions = years
         .sort((a, b) => a - b)
         .map((number) => (
            <option key={number} value={number}>
               {number}
            </option>
         ));
   }

   if (data !== null && season !== 'all') {
      data = data.filter((episode) => episode.seriesNumber === Number(season));
   }

   if (data !== null && year !== 'all') {
      if (year === 'none') {
         data = data.filter((episode) => episode.year === undefined);
      } else {
         data = data.filter((episode) => episode.year === Number(year));
      }
   }

   const filteredList = data
      ?.filter(
         (episode) =>
            query === '' ||
            episode.summary.toUpperCase().includes(query.toUpperCase()) ||
            episode.fullDescription.toUpperCase().includes(query.toUpperCase()),
      )
      .sort((a, b) => (sort === 'desc' ? b.episodeNumber - a.episodeNumber : a.episodeNumber - b.episodeNumber))
      .sort((a, b) => (sort === 'desc' ? b.seriesNumber - a.seriesNumber : a.seriesNumber - b.seriesNumber));

   const episodeOutput = filteredList?.map((episode) => {
      return (
         <div key={episode.assetId}>
            <h4 style={{ marginBottom: '0px' }}>
               S{episode.seriesNumber} E{episode.episodeNumber} - {getHighlightedText(episode.summary, query)}
            </h4>
            <h5 style={{ marginTop: '5px', marginBottom: '5px', color: 'darkgray' }}>
               {episode.year} | {episode.fullTitle} | Duration: {episode.durationLabel}
            </h5>
            <a href={'https://www.channel4.com' + episode.hrefLink}>{episode.fullDescription}</a>
         </div>
      );
   });

   const emptyFooter =
      filteredList !== undefined && filteredList.length === 0 ? (
         <div>
            <h3>
               Please contact Channel 4 to organise an audition <a href='https://www.channel4.com/4viewers/take-part/come-dine-me'>here</a>
            </h3>
         </div>
      ) : (
         <></>
      );

   return (
      <div>
         <div style={{ backgroundColor: '#f7fcfc', padding: '20px', borderBottom: '1px solid lightgray' }}>
            <Helmet>
               <title>DB Come Dine With Me</title>
            </Helmet>
            Search: <input style={{ marginLeft: '5px', marginRight: '10px' }} type='text' onChange={onChangeSearch} value={query}></input>
            Highlight results (might be a bit slow lol):
            <input style={{ marginLeft: '5px' }} type='checkbox' value={highlightResult} onChange={onChangeHighlightResult} />
            <br />
            Season:
            <select
               style={{ marginTop: '10px', marginBottom: '8px', marginLeft: '5px', marginRight: '17px' }}
               onChange={onChangeSeason}
               value={season}
            >
               <option value='all'>All</option>
               {seasonNumberOptions}
            </select>
            Year:
            <select
               style={{ marginTop: '10px', marginBottom: '8px', marginLeft: '6px', marginRight: '10px' }}
               onChange={onChangeYear}
               value={year}
            >
               <option value='all'>All</option>
               {yearOptions}
               <option value='none'>None</option>
            </select>
            Sort:
            <select style={{ marginTop: '10px', marginBottom: '8px', marginLeft: '6px' }} onChange={onChangeSort} value={sort}>
               <option value='desc'>Descending</option>
               <option value='asc'>Ascending</option>
            </select>
            <span style={{ marginLeft: '10px' }}>Total: {filteredList !== undefined ? filteredList.length : 0}</span> episodes
            <br />
            <span style={{ marginTop: '10px', color: 'darkgray' }}>
               Please note I think you will need a UK VPN and C4 account to watch these links
            </span>
            <Marquee autoFill style={{ color: 'lightgray', marginRight: '10px', opacity: '50%' }}>
               In order to become the perfect dinner party host, each participant takes turn during the week to cook and entertain the rest.
               The rivals, meanwhile, snoop around the house and award points in the end. 
            </Marquee>
         </div>
         <div style={{ margin: '20px' }}>
            {episodeOutput}
            {emptyFooter}
         </div>
      </div>
   );
};

export default ComeDineWithMe;
