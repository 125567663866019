import React from 'react';
import { Helmet } from 'react-helmet';
import GetComeDineWithMeJson from '../../hooks/getComeDineWithMeJson.ts';

const Runners = () => {
   const data = GetComeDineWithMeJson();

   const onGetCdwmJsonWithDatesClick = async () => {
      const finishedData = data.map((episode) => {
         const updatedEpisode = episode;

         if (episode.fullTitle !== '') {
            const rawDate = episode.title.split(':')[1].trim();
            updatedEpisode.date = Date.parse(rawDate);
            updatedEpisode.year = new Date(updatedEpisode.date).getFullYear();
         } else {
            updatedEpisode.date = 0;
         }

         return updatedEpisode;
      });

      const fileName = 'comedinewithmejson';
      const json = JSON.stringify(finishedData, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + '.json';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
   };

   return (
      <div style={{ margin: '20px' }}>
         <Helmet>
            <title>Runners</title>
         </Helmet>

         <input type='button' onClick={onGetCdwmJsonWithDatesClick} value='Get CDWM json with dates'></input>
      </div>
   );
};

export default Runners;
