import React from 'react';
import { Helmet } from 'react-helmet';

function As() {
   return (
      <div className='App'>
         <Helmet>
            <title>Very cool</title>
         </Helmet>

         <header className='App-header'>
            <span style={{ fontWeight: 'bold' }}>Official confirmation that Rachel is indeed cool as</span>
            <span style={{ fontSize: 20, marginTop: '20px' }}>"Rachel shot a 10 from the 10 line" - Obama</span>
            <span style={{ fontSize: 20 }}>"Rachel was technically correct about the Tudors" - David</span>
            <span style={{ fontSize: 20 }}>"Listening at 2.5x is a bit nuts" - Everyone</span>
         </header>
      </div>
   );
}

export default As;
