import React from 'react';
import Home from './Home.tsx';

function HomeWrapper() {
   return (
      <div style={{ backgroundColor: 'black' }}>
         <Home />
      </div>
   );
}

export default HomeWrapper;
