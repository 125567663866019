import React from 'react';
import { Helmet } from 'react-helmet';

function IsCobAnEldenLordYet() {
   return (
      <div className='App'>
         <Helmet>
            <title>No!</title>
         </Helmet>

         <header className='App-header'>
            <span style={{ fontWeight: 'bold' }}>No</span>
            <span style={{ fontSize: 10, marginTop: '20px' }}>13/08/2024 - unsure at the moment lol</span>
            <span style={{ fontSize: 10 }}>20/07/2024 - hanging out on the erd tree</span>
            <span style={{ fontSize: 10 }}>17/07/2024 - killed sewer mogh</span>
            <span style={{ fontSize: 10 }}>17/07/2024 - he is sewering under city</span>
            <span style={{ fontSize: 10 }}>25/02/2022 - he started</span>
         </header>
      </div>
   );
}

export default IsCobAnEldenLordYet;
