import { useEffect, useState } from 'react';

import Quote from '../models/Quote.ts';
import getJson from '../utils/getJson.ts';

const GetTheWireQuotesJson = (): Quote[] => {
   const [data, setData] = useState<Quote[]>();

   const fetchData = async () => {
      try {
         const response = await getJson('thewirequotes.json', 'json');

         setData(response);
      } catch (error) {
         console.error(error);
      }
   };

   useEffect(() => {
      fetchData();
   }, []);

   return data as Quote[];
};

export default GetTheWireQuotesJson;
