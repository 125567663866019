import React from 'react';

import { Helmet } from 'react-helmet';
import comedinewithmefull from './comedinewithmefull.json';

const ComeDineWithMe = () => {
   return (
      <div>
         <Helmet>
            <title>Come Dine With Me data</title>
         </Helmet>

         <pre>{JSON.stringify(comedinewithmefull, null, 2)}</pre>
      </div>
   );
};

export default ComeDineWithMe;
