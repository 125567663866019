import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import GetTheWireQuotesJson from '../../hooks/getTheWireQuotesJson.ts';
import './home.css';

function Home() {
   const [index, setIndex] = useState<number | undefined>(undefined);

   const data = GetTheWireQuotesJson();

   if (data === undefined) {
      return (
         <div className='Quote'>
            <Helmet>
               <title>Hi</title>
            </Helmet>
         </div>
      );
   }

   if (index === undefined) {
      setIndex(Math.floor(Math.random() * data.length));
   }

   if (index === undefined) {
      return (
         <div className='Quote'>
            <Helmet>
               <title>Hi</title>
            </Helmet>
         </div>
      );
   }

   const quote = data[index];

   return (
      <div className='Quote'>
         <Helmet>
            <title>Hi</title>
         </Helmet>

         <header className='Quote-top'>"{quote.quote}"</header>
         <header className='Quote-bottom'>--{quote.person}</header>
      </div>
   );
}

export default Home;
