import React from 'react';

import Helmet from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import As from './components/as/as.tsx';
import ComeDineWithMe from './components/comedinewithme/comedinewithme.tsx';
import ComeDineWithMeFull from './components/comedinewithme/comedinewithmefull.tsx';
import HomeWrapper from './components/home/HomeWrapper.tsx';
import IsCobAnEldenLordYet from './components/iscobaneldenlordyet/iscobaneldenlordyet.tsx';
import Runners from './components/runners/runners.tsx';

function App() {
   return (
      <div className='application'>
         <Helmet>
            <title>Title broke!</title>
         </Helmet>
         <Routes>
            <Route path='/' element={HomeWrapper()} />
            <Route path='/iscobaneldenlordyet' element={IsCobAnEldenLordYet()} />
            <Route path='/as' element={As()} />
            <Route path='/comedinewithmefull' element={ComeDineWithMeFull()} />
            <Route path='/comedinewithme' element={ComeDineWithMe()} />
            <Route path='/runners' element={Runners()} />
         </Routes>
      </div>
   );
}

export default App;
